/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="pb--80 pt--80" name={"info"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--30 pb--0 pt--50" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" style={{"maxWidth":""}} content={"Pańska 18, Warszawa"}>
              </Title>

              <Text className="text-box" content={"+48 797 811 2X0<br>info@twoje-stronyy.com<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--16 pb--0 pt--0" style={{"maxWidth":900}} columns={"4"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"Pon—Pt<br>7:00—15:00<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"Sob—Nd<br>7:00—15:00<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mt--16 pb--40 pt--0" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" style={{"maxWidth":524}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"p933laj9vhs"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Brno"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}